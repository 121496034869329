import * as React from "react"
import '../neat-basics/index.scss'
import "../pages/theming.scss"
import "../styles/styles.scss"
import {graphql} from "gatsby";
import {BaseArticle} from "../modules/base-article";
import NewsItem from "../modules/news-item";
import Main from "../modules/main";

const ThoughtArticle = (articleData: any) => {
    const article = articleData.data.markdownRemark
    const olderArticles = articleData.data.allMarkdownRemark.nodes.filter((a: any) => a.frontmatter.date < article.frontmatter.date)
    const latestNews = articleData.data.news.nodes[0];

    return <Main title={article.frontmatter.title}>
    <BaseArticle
        content={article.html}
        title={article.frontmatter.title}
        image={article.frontmatter.image}
        teaserSectionTitle="Further reads"
        teaseredArticles={olderArticles.slice(0, 2)}
        sideContent={latestNews && <NewsItem text={latestNews.frontmatter?.text} date={latestNews.frontmatter.date} url={latestNews.frontmatter.url} label={latestNews.frontmatter.linkLabel} />}
        date={article.frontmatter.date}
        activePage="newsandthoughts"
    />
  </Main>
}

export default ThoughtArticle

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        image {
           childImageSharp {
             gatsbyImageData(width: 1500)
           }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "thought_article"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title 
        }
      }
    }
    news: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "news"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
         frontmatter {
          date
          text
          url
          linkLabel
        }
      }
    }
  }
`
